import React from "react"
import styled from "styled-components"
import { rem } from "@src/theme"
import Box from "@components/styled/Box"

const RadioGroup = ({ label, value, name, onRadioChange, checked, onBlur }) => {
  return (
    <Box
      bg="white"
      height={"auto"}
      display="flex"
      alignItems="center"
      mb={3}
      borderRadius="2px"
      width="100%"
      maxWidth={rem(660)}
    >
      <Outer className="container" label={label}>
        {label}
        <input
          type="radio"
          checked={checked}
          name={name}
          id={name}
          value={value}
          onChange={onRadioChange}
          onBlur={onBlur}
        />
        <span className="checkmark"></span>
      </Outer>
    </Box>
  )
}

export default RadioGroup

const Outer = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.black};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    margin-top: 3.25px;
  }

  /* &:hover {
    input ~ .checkmark {
      background-color: #ccc;
    }
  } */

  input:checked ~ .checkmark {
    border-color: ${props => props.theme.colors.black};
    background: white;
    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 10px;
      width: 10px;
      background: ${props => props.theme.colors.black};
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`
