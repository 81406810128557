import React from "react"
import styled from "styled-components"
import { rem } from "@src/theme"
import ErrorMessage from "@components/ErrorMessage"
import Box from "@components/styled/Box"

export const FormHead = ({ title }) => (
  <>
    <Box
      as="h1"
      fontSize={rem(28)}
      mb={4}
      p={4}
      pr={6}
      bg="blue"
      color="white"
      display="inline-block"
    >
      {title}
    </Box>

    <Box as="p" fontSize={rem(13)}>
      <Box as="span" color="orange" fontSize={rem(15)}>
        *
      </Box>{" "}
      marks required
    </Box>
  </>
)

export const FormActions = ({
  onSubmitClick,
  onClearClick,
  onDeleteClick,
  showDelete,
  submitDisabled,
  error,
  ctaLabel = "Create",
  hideClear
}) => {
  return (
    <>
      <Box display="flex">
        <SubmitBtn
          type="submit"
          onClick={onSubmitClick}
          disabled={submitDisabled}
        >
          {ctaLabel}
        </SubmitBtn>
        {!hideClear && <ClearBtn onClick={onClearClick}>Clear</ClearBtn>}
        {showDelete && <DeleteBtn onClick={onDeleteClick}>Delete</DeleteBtn>}
      </Box>
      <Box pb={7}>{error && <ErrorMessage />}</Box>
    </>
  )
}

export const FormSection = ({ title, children }) => (
  <Section>
    <h1>{title}</h1>
    {children}
  </Section>
)

export const FieldGroup = ({ required, label, name, children }) => (
  <div className="field">
    <label className="field-label" htmlFor={name}>
      {label}{" "}
      {required && (
        <Box as="span" color="orange">
          *
        </Box>
      )}
    </label>
    {children}
  </div>
)
export const Section = styled(Box)`
  h1 {
    font-size: ${rem(18)};
    margin-bottom: ${p => p.theme.space[5]};
  }
  padding-top: ${p => p.theme.space[6]};
  padding-bottom: ${p => p.theme.space[6]};
  border-bottom: 1px solid #e6e6e6;
`

export const Textarea = styled.textarea`
  width: 100%;
  max-width: ${rem(420)};
  border: 1px solid #c4c4c5;
  border-color: #c4c4c4;
  padding: ${rem(5)};
  padding-left: ${rem(12)};
  border-radius: 3px;
  min-height: ${rem(44)};
  font-family: "NeueText";
  font-size: 1rem;
`

export const SubmitBtn = styled.button`
  background-color: ${p => p.theme.colors.blue};
  color: ${p => p.theme.colors.white};
  font-family: "NeueDisplay";
  border: 1px solid ${p => p.theme.colors.blue};
  border-radius: 100px;
  padding: ${rem(8)} ${rem(40)} ${rem(8)} ${rem(40)};
  text-transform: uppercase;
  font-size: ${rem(14)};
  margin-top: ${rem(40)};
  margin-right: ${rem(10)};
`

export const ClearBtn = styled(SubmitBtn)`
  border-color: ${p => p.theme.colors.black};
  background-color: transparent;
  color: ${p => p.theme.colors.black};
`

export const DeleteBtn = styled(SubmitBtn)`
  border-color: ${p => p.theme.colors.orange};
  background-color: transparent;
  color: ${p => p.theme.colors.orange};
`

export const Form = styled.div`
  .field {
    margin-bottom: ${rem(30)};

    &-label {
      display: block;
      text-transform: uppercase;
      color: #545454;
      font-family: "NeueDisplay";
      font-size: ${rem(10)};
      margin-bottom: ${rem(7)};
    }
  }

  .field-date {
    display: flex;
    justify-content: space-between;

    > div {
      flex: 0.3;
    }
  }
`
