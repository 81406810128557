import React from "react"
import { ClipLoader } from "react-spinners"
import Box from "@components/styled/Box"

const Loading = () => (
  <Box p={7} display="flex" justifyContent="center">
    <ClipLoader sizeUnit="px" size={150} />
  </Box>
)

export default Loading
