import React from "react"
import { rem } from "@src/theme"
import Box from "@components/styled/Box"
import Radio from "@components/Radio"
import get from "lodash/get"

const RadioGroup = props => {
  const { options, error, name, values, onChange, onBlur } = props

  return (
    <>
      {options.map(o => (
        <Radio
          key={o.value}
          label={o.label}
          value={o.value}
          name={name}
          onRadioChange={onChange}
          checked={get(values, name) === o.value}
          onBlur={onBlur}
        />
      ))}
      {error && (
        <Box as="span" color="red" display="block" mt={2} fontSize={rem(12)}>
          {error}
        </Box>
      )}
    </>
  )
}

export default RadioGroup
