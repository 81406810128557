import React from "react"
import styled from "styled-components"
const ErrorMessage = () => (
  <Outer>
    <p>
      There was an error. Try submitting again. If that doesn't work, it could
      be that:
    </p>
    <ul>
      <li>
        You entered a wrong value type in a field, e.g letter value in a number
        field
      </li>
      <li>Somebody has changed/modified fields on Airtable</li>
    </ul>
  </Outer>
)

const Outer = styled.div`
margin-top: ${p => p.theme.space[4]};
p {
    margin-bottom: ${p => p.theme.space[3]};
    color: ${p => p.theme.colors.orange};
}
ul {
    list-style-type: disc;
    font-size: ${p => p.theme.fontSizes[1]};
    li {
        margin-bottom: 5px;
        text-indent: 50px;
    }
}
li {
    list-style
}
`

export default ErrorMessage
