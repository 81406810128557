import React from "react"
import { Link } from "gatsby"
import Box from "@components/styled/Box"
import ArrowBack from "@components/assets/svg/arrow-back.svg"
import { rem } from "@src/theme"

const Back = ({ to = "/" }) => (
  <Box display="flex" alignItems="center" mb={5}>
    <Box mr={3} width={rem(10)}>
      <ArrowBack width="100%" />
    </Box>
    <Link to={to} style={{ textDecoration: "none", fontSize: rem(14) }}>
      Back
    </Link>
  </Box>
)

export default Back
